
.badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 0 3px;
  border-radius: 50%;
  background: #ffa91b;
  color: #fff;
  text-align: center;
  font-size: 12px;
}
