
@keyframes jumbo-slider-slide-left-in {
  from { left: 100%; }
  to { left: 0%; }
}

@keyframes jumbo-slider-slide-right-in {
  from { left: -100%; }
  to { left: 0%; }
}

@keyframes jumbo-slider-slide-left-out {
  from { left: 0%; }
  to { left: -100%; }
}

@keyframes jumbo-slider-slide-right-out {
  from { left: 0%; }
  to { left: 100%; }
}

.slide-left-in {
  animation: jumbo-slider-slide-left-in 700ms ease-out;
}

.slide-right-in {
  animation: jumbo-slider-slide-right-in 700ms ease-out;
}

.slide-left-out {
  animation: jumbo-slider-slide-left-out 700ms ease-out;
}

.slide-right-out {
  animation: jumbo-slider-slide-right-out 700ms ease-out;
}

.jumbo-slider {
  position: relative;
  overflow: hidden;
  clear: both;
  height: 500px;
  color: #fff;
  margin-bottom: 20px;
}

.jumbo-slider-item {
  position: absolute;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jumbo-slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.jumbo-slider-dots {
  position: absolute;
  bottom: 0.5em;
  width: 100%;
  z-index: 10;
  text-align: center;
}

.jumbo-slider-dot {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: #fff;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50%;
  margin: 0.5em;
  padding: 0;
  transition: background-color 300ms;
  cursor: pointer;
  outline: none;
}

.jumbo-slider-dot:hover, .jumbo-slider-dot.active {
  transform: scale(1.3);
  background-color: #ffa91b;
}

@media (max-width: 768px) {
  .jumbo-slider-item {
    height: 300px;
  }
}
