
.row {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
	box-sizing: border-box;
}

.row.gutter {
  margin: 0;
}

.row.gutter > .col, .side-gutter {
  padding: 0 15px;
}

.full-width {
  width: 100%;
}

.col {
  flex: 1;
  min-width: 40px;
  box-sizing: border-box;
  word-break: break-all;
}

.col.top {
  align-self: flex-start;
}

.col.center {
  align-self: center;
}

.col.bottom {
  align-self: flex-end;
}

.col.full {
  flex: 0 0 100%;
}

.col.half {
  flex: 0 0 50%;
  max-width: 50%;
}

.col.one-third {
  flex: 0 0 33.3%;
  max-width: 33.3%;
}

.col.quarter {
  flex: 0 0 25%;
  max-width: 25%;
}

.md-shown, .sm-shown {
  display: none;
}

@media all and (max-width: 1024px) {
  .col.md-auto {
    flex: 1;
  }

  .col.md-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col.md-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col.md-one-third {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .col.md-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .md-hidden {
    display: none;
  }

  .md-shown {
    display: block;
  }
}

@media all and (max-width: 768px) {
  .col.sm-auto {
    flex: 1;
  }

  .col.sm-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col.sm-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col.sm-one-third {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .col.sm-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sm-hidden {
    display: none;
  }

  .sm-shown {
    display: block;
  }
}
