
.stepper {
  margin: 20px 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.step {
  position: relative;
  display: inline-block;
  padding: 13px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #d0d0d0;
}

.step:not(:last-child)::after {
  position: absolute;
  content: '';
  top: -5px;
  right: -15px;
  width: 38px;
  height: 38px;
  border-right: 12px solid #fff;
  border-bottom: 12px solid #fff;
  transform: scaleX(0.6) rotate(-45deg);
  z-index: 1;
}

.step.active {
  background-color: #ffa91b;
}

@media (max-width: 768px) {
}
