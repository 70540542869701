
.bbs-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 5px 0;
}

.bbs-search-form select {
  margin-right: 10px;
  padding: 0 5px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  color: #535051;
  background-color: #fff;
  vertical-align: middle;
}

.bbs-search-form a, .bbs-search-form button {
  vertical-align: middle;
}

.bbs-post-list-empty {
  margin: 40px 0 60px 0;
  text-align: center;
}
