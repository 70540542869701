
.page {
  position: relative;
  background: #fff;
  overflow: hidden;
}

.page img {
  max-width: 100%;
  height: auto;
}

.page-help {
  margin: 20px 0;
  text-align: center;
}

.page-help p {
  margin-bottom: 20px;
  line-height: 18px;
}

.page-help a > button {
  margin: 0 5px;
}
