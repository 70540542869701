
.login-panel {
  width: 100%;
  height: 400px;
  margin-bottom: 200px;
  background: linear-gradient(to bottom, #d0d0d0, #fff);
}

.login-form {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding-top: 85px;
}

.login-header {
  height: 50px;
  margin-bottom: 20px;
  font-size: 36px;
  border-bottom: 1px solid #535051;
  text-align: center;
}

.login-form input:not([type=checkbox]) {
  width: 200px;
  margin-bottom: 10px;
}

.login-form input[type=checkbox] {
  height: 14px;
  vertical-align: middle;
}

.login-form label {
  display: block;
  margin-bottom: 20px;
}

.login-form button[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  width: 90px;
  height: 70px;
}

.login-buttons {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.login-buttons a {
  display: inline-block;
  margin: 0 3px;
}

.login-buttons button {
  font-size: 12px;
}

.login-social-services {
  margin: 2em;
  text-align: center;
}
