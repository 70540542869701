
.page-header > .container {
  min-height: 100px;
  padding: 32px 0;
  background: linear-gradient(to right, #d0d0d0, #fff);
  font-size: 36px;
  text-align: center;
}

@media (max-width: 768px) {
  .page-header > .container {
    min-height: 60px;
    padding: 18px 0;
    font-size: 24px;
  }
}
