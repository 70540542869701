
@keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

@keyframes page-slide-right {
  from { left: -100vw; }
  to { left: 0 }
}

.top-menu-search-box form {
  position: relative;
  display: block;
  width: 182px;
  height: 32px;
  border: 1px solid #d0d0d0;
  margin: 4px;
  padding: 0;
  overflow: hidden;
}

.top-menu-search-box input {
  display: block;
  width: 150px;
  height: 30px;
  padding: 0 10px;
  border-radius: 0;
  border: 0;
  font-size: 12px;
}

.top-menu-search-box button {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  background-color: #fff;
}

@media (min-width: 769px) {
  .top-menu {
    position: relative;
    min-height: 40px;
  }

  .top-menu-container {
    position: relative;
    height: 40px;
    background: #535051;
    color: #d0d0d0;
    z-index: 90;
  }

  .top-menu.sticky .top-menu-container {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    transition: box-shadow 500ms linear;
  }

  .top-menu-items {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .top-menu-items a {
    display: inline-block;
    padding: 0 20px;
  }

  .top-menu-item {
    position: relative;
    display: inline-block;
    line-height: 40px;
    font-size: 18px;
    cursor: pointer;
  }

  .top-menu-item:hover {
    background: #ffa91b;
    color: #fff;
  }

  .top-menu-sub-menu {
    position: absolute;
    left: 0;
    width: 190px;
    margin: 0;
    display: none;
    background: #d0d0d0;
    color: #535051;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }

  .top-menu-sub-item {
    position: relative;
    animation: slide-down 100ms linear;
    overflow: hidden;
  }

  .top-menu-sub-item:hover {
    background: #535051;
    color: #fff;
  }

  .top-menu-sub-item a {
    display: block;
  }

  .top-menu-item:hover .top-menu-sub-menu {
    display: block;
  }

  .top-menu-search-box {
    float: right;
  }
}

@media (max-width: 768px) {
  .top-menu-button {
    width: 39px;
    height: 39px;
    margin: 0;
    padding: 10px 5px;
    border: 0;
    text-align: center;
  }

  .top-menu-button span {
    display: block;
    height: 2px;
    margin-bottom: 6px;
    background-color: #d0d0d0;
  }

  .top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    text-align: center;
    transition: background-color 300ms linear;
  }

  .top-menu:not(.collapsed) {
    background-color: #fff;
  }

  .top-menu.collapsed {
    width: 39px;
    height: 39px;
  }

  .top-menu.collapsed .container {
    display: none;
  }

  .top-menu .container {
    animation: page-slide-right 300ms ease-in;
  }

  .top-menu-logo {
    margin: 27px 0 20px 0;
  }

  .top-menu-items {
    display: block;
    margin: 20px;
    text-align: left;
    line-height: 40px;
    text-align: center;
  }

  .top-menu-item > a {
    display: block;
    border-bottom: 1px solid #d0d0d0;
  }

  .top-menu-item {
    display: block;
    font-size: 16px;
  }

  .top-menu-sub-menu {
    display: block;
    line-height: 40px;
  }

  .top-menu-sub-item {
    display: inline-block;
    padding-left: 15px;
  }

  .top-menu-search-box {
    width: 190px;
    margin: 0 auto;
  }
}
