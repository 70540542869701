
.bbs-list-card-item {
  position: relative;
  padding: 15px;
  overflow: hidden;
}

.bbs-list-card-category-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
}

.bbs-list-card-thumbnail {
  text-align: center;
}

.bbs-list-card-thumbnail img {
  width: 100%;
  height: auto;
}

.bbs-list-card-title {
  margin: 10px 0;
  line-height: 16px;
  font-size: 14px;
  color: #535051;
}

.bbs-list-card-secret {
  margin: 0 5px;
}

.bbs-list-card-secret img {
  vertical-align: middle;
}

.bbs-list-card-description {
  margin-top: 15px;
  color: #d0d0d0;
}

.bbs-list-card-date {
  display: block;
  float: right;
}

.bbs-list-card-date span {
  font-size: 12px;
}

.bbs-list-card-author {
  font-size: 12px;
}

.bbs-list-card-comment-count {
  display: block;
  float: right;
  margin: 0 10px;
}

.bbs-list-card-comment-count span {
  font-size: 12px;
}

.bbs-list-card-comment-count img {
  margin: 0 5px;
  vertical-align: middle;
}