
.course-image {
  flex: none;
  width: 230px;
  height: 200px;
  margin-bottom: 40px;
  text-align: center;
}

.course-content {
  margin-bottom: 40px;
}

.course-title {
  font-size: 24px;
  margin-bottom: 5px;
}

.course-description {
  line-height: 20px;
}

.course-search-form {
  margin: 20px 0 40px 0;
  text-align: center;
}

.course-search-form img {
  margin: 0 10px;
}

.course-search-form img, .course-search-form span {
  vertical-align: middle;
}

.course-list-empty {
  margin: 40px;
  text-align: center;
}

.course-buttons button {
  margin-right: 10px;
}
