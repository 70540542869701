@keyframes rotation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 40px;
  overflow: hidden;
  z-index: 50;
  cursor: wait;
}

.loader.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: auto;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader > img {
  display: inline-block;
  animation: rotation 1s infinite steps(8);
}

.loader-error {
  text-align: center;
}

.loader-error > div {
  margin: 10px;
}
