
.tab-menu {
  margin: 20px 0;
  text-align: center;
}

.tab-item {
  display: inline-block;
  cursor: pointer;
}

.tab-item button {
  display: inline-block;
  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
  border: 0;
  font-size: 18px;
  font-family: inherit;
  color: #595959;
  background-color: transparent;
}

.tab-item.active, .tab-item:hover {
  border-bottom: 2px solid #535051;
}

@media (max-width: 768px) {
  .tab-item button {
    height: 30px;
    padding: 5px 10px;
    font-size: 16px;
  }
}
