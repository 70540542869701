@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

html, body {
	font-family: "Noto Sans KR", "돋움";
	background-color: #d0d0d0;
	color: #535051;
  font-size: 14px;
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: 600;
}

button {
	height: 30px;
	margin: 0;
	padding: 0 15px;
	background-color: #fff;
	color: #535051;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
	cursor: pointer;
	outline: none;
	transition: all 100ms linear;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
}

button.large {
	height: 40px;
	padding: 0 20px;
	background-color: #d0d0d0;
	color: #535051;
	border: 0;
	font-size: 18px;
}

button.primary {
	background-color: #a8cf38;
	color: #535051;
	border: 0;
}

button:hover {
	background-color: #ffa91b;
}

button:disabled {
	background-color: #fff;
	color: #d0d0d0;
	cursor: default;
}

.icon-button {
	padding: 0 15px 0 8px;
}

.icon-button img {
	margin-right: 4px;
	vertical-align: middle;
}

input, select {
	max-width: 100%;
	height: 30px;
	margin: 0;
	padding: 0 5px;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
	outline: none;
}

textarea {
	max-width: 100%;
	margin: 0;
	padding: 5px;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
}

input[type="checkbox"], input[type="radio"] {
	margin: 0 3px;
}

input:not([type="checkbox"]):not([type="radio"]), textarea {
	box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

input::placeholder, textarea::placeholder {
	color: #d0d0d0;
}

dl.data-table {
	margin: 10px 0;
	border-bottom: 1px solid #d0d0d0;
}

dl.data-table dt, dl.data-table dd {
	display: block;
	min-height: 40px;
	line-height: 20px;
	padding: 9px;
	font-size: 14px;
	color: #535051;
	overflow: hidden;
	border-top: 1px solid #d0d0d0;
}

dl.data-table dt:first-of-type, dl.data-table dd:first-of-type {
	border-top: 0;
}

dl.data-table dt {
	float: left;
	clear: both;
	width: 25%;
	font-weight: 600;
	text-align: right;
}

dl.data-table dd {
	border-left: 1px solid #d0d0d0;
}

.captcha, input[name="captcha"] {
  display: none;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
