
.bbs-list-media-item {
  border-bottom: 1px solid #d0d0d0;
  overflow: hidden;
}

.bbs-list-media-thumbnail {
  float: left;
}

.bbs-list-media-thumbnail img {
  width: 80px;
  height: 80px;
  margin: 10px 15px 10px 10px;
}

.bbs-list-media-title {
  margin: 10px 0 5px 0;
  font-size: 14px;
  font-weight: 600;
}

.bbs-list-media-excerpt {
  line-height: 16px;
  font-size: 14px;
  margin: 5px 10px 10px 0;
}

.bbs-list-media-description {
  margin: 10px 0;
  font-size: 12px;
  color: #d0d0d0;
}

.bbs-list-media-description span {
  margin-right: 10px;
  vertical-align: middle;
} 

.bbs-list-media-description img {
  margin: 0 5px;
  vertical-align: middle;
}
