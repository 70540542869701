
.about-header {
  height: 240px;
}

#about-header-image-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

#about-header-image-2 {
  position: absolute;
  left: 200px;
  top: 90px;
}

#about-header-image-3 {
  position: absolute;
  right: 24px;
  top: 24px;
}

#about-header-image-4 {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
