
.footer {
  margin-top: 80px;
  background: #d0d0d0;
  font-size: 14px;
  text-align: center;
}

.footer-menu {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  white-space: nowrap;
}

.footer-menu-item {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-left: 1px solid #fff;
}

.footer-menu-item:last-child {
  border-right: 1px solid #fff;
}

.footer-menu-item:hover {
  background: #535051;
  color: #fff;
  cursor: pointer;
}

.footer-logo {
  margin: 30px;
}

.footer dt, .footer dd {
  display: inline-block;
  margin: 0 3px;
  line-height: 20px;
}

.footer dd {
  font-weight: 600;
}

.footer dd a {
  font-weight: normal;
}

.footer dd a:hover {
  text-decoration: underline;
}

.footer-copyright {
  padding: 20px 0;
}

@media (max-width: 768px) {
  .footer-menu-item {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}
