
.top-logo {
  display: flex;
  align-items: center;
  height: 150px;
  margin: 40px 0 0 0;
  padding: 0;
  text-align: center;
}

.top-logo img {
  width: 120px;
  height: 96px;
}

@media (max-width: 768px) {
  
}
