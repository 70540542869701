
.breadcrumb {
  height: 40px;
  margin: 0 5px;
  font-size: 14px;
  text-align: right;
}

.breadcrumb-item {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.breadcrumb-item:not(:last-child):after {
  content: '•';
}

.breadcrumb button {
  display: inline-block;
  padding: 0 10px;
  border: 0;
  color: #595959;
  background-color: transparent;
  font-family: inherit;
}

.breadcrumb-item:not(:last-child) button:hover {
  color: #ffa91b;
  background-color: transparent;
}

.breadcrumb-item.active {
  cursor: default;
}
