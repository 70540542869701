
.course-view-buttons {
  margin: 40px;
  text-align: center;
}

.course-view-buttons button {
  margin: 0 5px;
}

.course-application-item {
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid #d0d0d0;
  border-bottom: 0;
  overflow: hidden;
}

.course-application-item:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.course-application-name {
  width: 80px;
  line-height: 20px;
  padding: 10px;
}

.course-application-message {
  flex: 1;
  line-height: 20px;
  padding: 10px;
}

.course-application-date {
  line-height: 20px;
  padding: 10px;
  text-align: center;
}

.course-application-item button {
  border: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 12px;
}
