
.bbs-post-view {
  position: relative;
  overflow: hidden;
}

.bbs-post-view-header {
  margin: 20px 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-post-view-title {
  padding: 13px 13px 13px 0;
}

.bbs-post-view-title span:first-child {
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}

.bbs-category-badge {
  margin: 0 8px;
  padding: 2px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
}

.bbs-post-view-description {
  float: right;
  margin: 0;
  padding: 9px;
}

.bbs-post-view-description li {
  display: inline-block;
  margin: 8px;
  padding: 0;
}

.bbs-post-view-description img {
  vertical-align: middle;
  margin-right: 5px;
}

.bbs-post-view-description span {
  font-size: 14px;
}

.bbs-post-view-footer {
  margin: 20px 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-post-view-buttons {
  margin: 20px 0;
  text-align: right;
}

.bbs-post-view-buttons button {
  margin-left: 10px;
}

.bbs-post-view-file-list {
  overflow: hidden;
}

.bbs-post-view-file-list ul {
  float: right;
  padding: 0 10px;
}

.bbs-post-view-file-list li {
  line-height: 20px;
  text-align: right;
}

.bbs-post-view-file-list li span {
  margin: 0 5px;
}

.bbs-post-view-file-list a:hover {
  text-decoration: underline;
}

.bbs-comment-list form {
  margin-bottom: 20px;
}

.bbs-comment-list textarea {
  width: 100%;
}

.bbs-comment-item {
  position: relative;
  display: flex;
  border-bottom: 1px solid #d0d0d0;
  animation: fade-in 300ms linear;
}

.bbs-comment-avatar {
  width: 48px;
  height: 48px;
  margin: 6px;
  border: 1px solid #d0d0d0;
  background-position: center center;
  background-size: cover;
}

.bbs-comment-body {
  flex: 1;
}

.bbs-comment-description {
  margin: 6px;
  font-size: 12px;
  color: #d0d0d0;
}

.bbs-comment-description img {
  display: inline-block;
}

.bbs-comment-description span {
  display: inline-block;
  margin-right: 10px;
}

.bbs-comment-content {
  margin: 6px 20px 6px 6px;
  line-height: 16px;
  white-space: pre-wrap;
  font-size: 14px;
  color: #535051;
}

.bbs-comment-delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  border: 0;
  padding: 2px;
  margin: 0;
}

.bbs-comment-delete:hover {
  background-color: #535051;
}
