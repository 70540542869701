
.shop-item-not-ready {
  margin: 40px 0;
  text-align: center;
}

.shop-item-search-form {
  padding: 5px;
  text-align: center;
}

.shop-item-list {
  margin: 20px 0;
}

.shop-item {
  margin-bottom: 20px;
  text-align: center;
}

.shop-item-image {
  margin-bottom: 10px;
}

.shop-item-title {
  height: 30px;
  line-height: 20px;
  padding: 5px;
  font-size: 18px;
}

.shop-item-description {
  height: 30px;
  line-height: 20px;
  padding: 5px;
}

.shop-item-price {
  height: 30px;
  line-height: 20px;
  padding: 5px;
  font-size: 18px;
}
