
@keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  z-index: 100;
}

.top-nav-brand {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 18px;
}

.top-nav-links {
  position: absolute;
  top: 0;
  right: 0;
}

.top-nav-link {
  position: relative;
  display: inline-block;
  height: 40px;
  line-height: 20px;
  padding: 10px 15px;
  border-left: 1px solid #d0d0d0;
  font-size: 14px;
  vertical-align: middle;
}

.top-nav-link:last-child {
  border-right: 1px solid #d0d0d0;
}

.top-nav-link:hover, .top-nav-sub-menu a:hover {
  background: #535051;
  color: #fff;
}

.top-nav-link img {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.top-nav-avatar {
  border-radius: 50%;
}

.top-nav-sub-menu {
  display: none;
  background-color: #d0d0d0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.top-nav-link:hover .top-nav-sub-menu {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
}

.top-nav-sub-menu a {
  display: inline-block;
  width: 100%;
  line-height: 20px;
  padding: 10px;
  border: 0;
  color: #535051;
  animation: slide-down 100ms linear;
}
