
.file-uploader-list {
  margin-bottom: 10px;
  border-bottom: 0;
}

.file-uploader-list li {
  display: flex;
  align-items: center;
  border-top: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}

.file-uploader-list li:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.file-uploader-thumbnail {
  width: 40px;
  height: 40px;
}

.file-uploader-description {
  flex: 1;
  padding: 0 10px;
}

.file-uploader-delete {
  margin: 0 5px;
}
