
form {
	position: relative;
}

.form-field {
	line-height: 20px;
}

.form-label {
  width: 100%;
	font-weight: 600;
	font-size: 14px;
	color: #535051;
}

.form-label-important {
	margin-right: 5px;
	color: #ffa91b;
}

.form.horizontal .form-label {
	float: left;
	width: 25%;
	padding: 10px 25px 10px 10px;
	text-align: right;
}

.form-text {
	padding: 5px 0;
}

.form-control-row {
	margin-bottom: 10px;
}

.form-control {
	padding: 5px 0;
	font-size: 14px;
	overflow: hidden;
}

.form-control button, .form-control input, .form-control span {
	display: inline;
	vertical-align: middle;
	line-height: 30px;
}

.form-control a {
	text-decoration: underline;
}

.form-buttons {
  margin-top: 5px;
}

.form-buttons button[type="submit"] {
  margin-right: 10px;
}

@media (max-width: 768px) {
	.form.horizontal .form-label {
		float: none;
		width: 100%;
		padding: 0;
		text-align: left;
	}

  .form-buttons {
    text-align: center;
  }

  .form-buttons button {
    margin: 5px;
  }
}
