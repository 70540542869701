
.pagination {
  text-align: center;
}

.pagination button {
  display: inline-block;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  border: 0;
  font-size: 1em;
  font-family: inherit;
  color: #595959;
  background-color: transparent;
}

.pagination button:hover {
  border-bottom: 1px solid #ffa91b;
}

.pagination .active button {
  display: inline-block;
  color: #ffa91b;
  border-bottom: 1px solid #ffa91b;
}
