@keyframes rotation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.activity-list {
  margin: 20px 0;
}

.activity-item {
  color: #535051;
  margin: 0 15px;
}

.activity-date {
  text-align: right;
  font-size: 18px;
  margin: 10px 20px;
}

.activity-content {
  border-left: 2px solid #d0d0d0;
  line-height: 25px;
}

.activity-picture {
  margin: 20px;
}

.activity-message {
  white-space: pre-wrap;
  margin: 10px 20px;
}

.activity-tag {
  color: #ffa91b;
}

.activity-tag:hover {
  text-decoration: underline;
}

.activity-more {
  margin: 40px;
  text-align: center;
}

.activity-more > button > img {
  animation: rotation 1s infinite steps(8);
}

@media (max-width: 768px) {
  .activity-list {
    margin: 0;
  }

  .activity-date {
    margin: 40px 0 20px 0;
    text-align: center;
  }
}
