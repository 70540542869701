
.bbs-list-table-title {
  vertical-align: middle;
}

.bbs-list-table-category-badge {
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
}

.bbs-list-table-comment-count {
  margin: 0 5px;
  color: #ffa91b;
}

.bbs-list-table-comment-count img {
  vertical-align: middle;
}

.bbs-list-table-comment-count span {
  font-size: 12px;
}

.bbs-list-table-item img {
  vertical-align: middle;
  margin: 0 5px;
}

.bbs-list-table-item {
  margin: 20px 0;
}

.bbs-list-table {
  width: 100%;
  margin: 0;
  padding: 0;
}

.bbs-list-table tr {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-list-table tbody a:hover {
  text-decoration: underline;
}

.bbs-list-table th {
  min-height: 40px;
  line-height: 20px;
  margin: 0;
  padding: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #535051;
  border: 0;
}

.bbs-list-table td {
  min-height: 40px;
  line-height: 20px;
  margin: 0;
  padding: 10px;
  border: 0;
  color: #535051;
}

.bbs-list-table td.fit-content {
  width: 15%;
  white-space: nowrap;
}
