
.sub-header {
  height: 50px;
  line-height: 50px;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
  color: #535051;
  border-bottom: 1px solid #d0d0d0;
}
