@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

small {
	font-size: 0.8em;
}
html, body {
	font-family: "Noto Sans KR", "\B3CB\C6C0";
	background-color: #d0d0d0;
	color: #535051;
  font-size: 14px;
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: 600;
}

button {
	height: 30px;
	margin: 0;
	padding: 0 15px;
	background-color: #fff;
	color: #535051;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
	cursor: pointer;
	outline: none;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
}

button.large {
	height: 40px;
	padding: 0 20px;
	background-color: #d0d0d0;
	color: #535051;
	border: 0;
	font-size: 18px;
}

button.primary {
	background-color: #a8cf38;
	color: #535051;
	border: 0;
}

button:hover {
	background-color: #ffa91b;
}

button:disabled {
	background-color: #fff;
	color: #d0d0d0;
	cursor: default;
}

.icon-button {
	padding: 0 15px 0 8px;
}

.icon-button img {
	margin-right: 4px;
	vertical-align: middle;
}

input, select {
	max-width: 100%;
	height: 30px;
	margin: 0;
	padding: 0 5px;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
	outline: none;
}

textarea {
	max-width: 100%;
	margin: 0;
	padding: 5px;
	border: 1px solid #d0d0d0;
	border-radius: 0;
	font-size: 14px;
}

input[type="checkbox"], input[type="radio"] {
	margin: 0 3px;
}

input:not([type="checkbox"]):not([type="radio"]), textarea {
	box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
	color: #d0d0d0;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
	color: #d0d0d0;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
	color: #d0d0d0;
}

input::placeholder, textarea::placeholder {
	color: #d0d0d0;
}

dl.data-table {
	margin: 10px 0;
	border-bottom: 1px solid #d0d0d0;
}

dl.data-table dt, dl.data-table dd {
	display: block;
	min-height: 40px;
	line-height: 20px;
	padding: 9px;
	font-size: 14px;
	color: #535051;
	overflow: hidden;
	border-top: 1px solid #d0d0d0;
}

dl.data-table dt:first-of-type, dl.data-table dd:first-of-type {
	border-top: 0;
}

dl.data-table dt {
	float: left;
	clear: both;
	width: 25%;
	font-weight: 600;
	text-align: right;
}

dl.data-table dd {
	border-left: 1px solid #d0d0d0;
}

.captcha, input[name="captcha"] {
  display: none;
}

@-webkit-keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}


.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}


.row {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
	box-sizing: border-box;
}

.row.gutter {
  margin: 0;
}

.row.gutter > .col, .side-gutter {
  padding: 0 15px;
}

.full-width {
  width: 100%;
}

.col {
  flex: 1 1;
  min-width: 40px;
  box-sizing: border-box;
  word-break: break-all;
}

.col.top {
  align-self: flex-start;
}

.col.center {
  align-self: center;
}

.col.bottom {
  align-self: flex-end;
}

.col.full {
  flex: 0 0 100%;
}

.col.half {
  flex: 0 0 50%;
  max-width: 50%;
}

.col.one-third {
  flex: 0 0 33.3%;
  max-width: 33.3%;
}

.col.quarter {
  flex: 0 0 25%;
  max-width: 25%;
}

.md-shown, .sm-shown {
  display: none;
}

@media all and (max-width: 1024px) {
  .col.md-auto {
    flex: 1 1;
  }

  .col.md-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col.md-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col.md-one-third {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .col.md-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .md-hidden {
    display: none;
  }

  .md-shown {
    display: block;
  }
}

@media all and (max-width: 768px) {
  .col.sm-auto {
    flex: 1 1;
  }

  .col.sm-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col.sm-half {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col.sm-one-third {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .col.sm-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sm-hidden {
    display: none;
  }

  .sm-shown {
    display: block;
  }
}


.badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 0 3px;
  border-radius: 50%;
  background: #ffa91b;
  color: #fff;
  text-align: center;
  font-size: 12px;
}


@-webkit-keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

@keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
  z-index: 100;
}

.top-nav-brand {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 18px;
}

.top-nav-links {
  position: absolute;
  top: 0;
  right: 0;
}

.top-nav-link {
  position: relative;
  display: inline-block;
  height: 40px;
  line-height: 20px;
  padding: 10px 15px;
  border-left: 1px solid #d0d0d0;
  font-size: 14px;
  vertical-align: middle;
}

.top-nav-link:last-child {
  border-right: 1px solid #d0d0d0;
}

.top-nav-link:hover, .top-nav-sub-menu a:hover {
  background: #535051;
  color: #fff;
}

.top-nav-link img {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.top-nav-avatar {
  border-radius: 50%;
}

.top-nav-sub-menu {
  display: none;
  background-color: #d0d0d0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.top-nav-link:hover .top-nav-sub-menu {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
}

.top-nav-sub-menu a {
  display: inline-block;
  width: 100%;
  line-height: 20px;
  padding: 10px;
  border: 0;
  color: #535051;
  -webkit-animation: slide-down 100ms linear;
          animation: slide-down 100ms linear;
}


.top-logo {
  display: flex;
  align-items: center;
  height: 150px;
  margin: 40px 0 0 0;
  padding: 0;
  text-align: center;
}

.top-logo img {
  width: 120px;
  height: 96px;
}

@media (max-width: 768px) {
  
}


@-webkit-keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

@keyframes slide-down {
  from { max-height: 0; }
  to { max-height: 40px; }
}

@-webkit-keyframes page-slide-right {
  from { left: -100vw; }
  to { left: 0 }
}

@keyframes page-slide-right {
  from { left: -100vw; }
  to { left: 0 }
}

.top-menu-search-box form {
  position: relative;
  display: block;
  width: 182px;
  height: 32px;
  border: 1px solid #d0d0d0;
  margin: 4px;
  padding: 0;
  overflow: hidden;
}

.top-menu-search-box input {
  display: block;
  width: 150px;
  height: 30px;
  padding: 0 10px;
  border-radius: 0;
  border: 0;
  font-size: 12px;
}

.top-menu-search-box button {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  background-color: #fff;
}

@media (min-width: 769px) {
  .top-menu {
    position: relative;
    min-height: 40px;
  }

  .top-menu-container {
    position: relative;
    height: 40px;
    background: #535051;
    color: #d0d0d0;
    z-index: 90;
  }

  .top-menu.sticky .top-menu-container {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    -webkit-transition: box-shadow 500ms linear;
    transition: box-shadow 500ms linear;
  }

  .top-menu-items {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .top-menu-items a {
    display: inline-block;
    padding: 0 20px;
  }

  .top-menu-item {
    position: relative;
    display: inline-block;
    line-height: 40px;
    font-size: 18px;
    cursor: pointer;
  }

  .top-menu-item:hover {
    background: #ffa91b;
    color: #fff;
  }

  .top-menu-sub-menu {
    position: absolute;
    left: 0;
    width: 190px;
    margin: 0;
    display: none;
    background: #d0d0d0;
    color: #535051;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }

  .top-menu-sub-item {
    position: relative;
    -webkit-animation: slide-down 100ms linear;
            animation: slide-down 100ms linear;
    overflow: hidden;
  }

  .top-menu-sub-item:hover {
    background: #535051;
    color: #fff;
  }

  .top-menu-sub-item a {
    display: block;
  }

  .top-menu-item:hover .top-menu-sub-menu {
    display: block;
  }

  .top-menu-search-box {
    float: right;
  }
}

@media (max-width: 768px) {
  .top-menu-button {
    width: 39px;
    height: 39px;
    margin: 0;
    padding: 10px 5px;
    border: 0;
    text-align: center;
  }

  .top-menu-button span {
    display: block;
    height: 2px;
    margin-bottom: 6px;
    background-color: #d0d0d0;
  }

  .top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    text-align: center;
    -webkit-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }

  .top-menu:not(.collapsed) {
    background-color: #fff;
  }

  .top-menu.collapsed {
    width: 39px;
    height: 39px;
  }

  .top-menu.collapsed .container {
    display: none;
  }

  .top-menu .container {
    -webkit-animation: page-slide-right 300ms ease-in;
            animation: page-slide-right 300ms ease-in;
  }

  .top-menu-logo {
    margin: 27px 0 20px 0;
  }

  .top-menu-items {
    display: block;
    margin: 20px;
    text-align: left;
    line-height: 40px;
    text-align: center;
  }

  .top-menu-item > a {
    display: block;
    border-bottom: 1px solid #d0d0d0;
  }

  .top-menu-item {
    display: block;
    font-size: 16px;
  }

  .top-menu-sub-menu {
    display: block;
    line-height: 40px;
  }

  .top-menu-sub-item {
    display: inline-block;
    padding-left: 15px;
  }

  .top-menu-search-box {
    width: 190px;
    margin: 0 auto;
  }
}


.footer {
  margin-top: 80px;
  background: #d0d0d0;
  font-size: 14px;
  text-align: center;
}

.footer-menu {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  white-space: nowrap;
}

.footer-menu-item {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-left: 1px solid #fff;
}

.footer-menu-item:last-child {
  border-right: 1px solid #fff;
}

.footer-menu-item:hover {
  background: #535051;
  color: #fff;
  cursor: pointer;
}

.footer-logo {
  margin: 30px;
}

.footer dt, .footer dd {
  display: inline-block;
  margin: 0 3px;
  line-height: 20px;
}

.footer dd {
  font-weight: 600;
}

.footer dd a {
  font-weight: normal;
}

.footer dd a:hover {
  text-decoration: underline;
}

.footer-copyright {
  padding: 20px 0;
}

@media (max-width: 768px) {
  .footer-menu-item {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}


.page {
  position: relative;
  background: #fff;
  overflow: hidden;
}

.page img {
  max-width: 100%;
  height: auto;
}

.page-help {
  margin: 20px 0;
  text-align: center;
}

.page-help p {
  margin-bottom: 20px;
  line-height: 18px;
}

.page-help a > button {
  margin: 0 5px;
}


@-webkit-keyframes jumbo-slider-slide-left-in {
  from { left: 100%; }
  to { left: 0%; }
}

@keyframes jumbo-slider-slide-left-in {
  from { left: 100%; }
  to { left: 0%; }
}

@-webkit-keyframes jumbo-slider-slide-right-in {
  from { left: -100%; }
  to { left: 0%; }
}

@keyframes jumbo-slider-slide-right-in {
  from { left: -100%; }
  to { left: 0%; }
}

@-webkit-keyframes jumbo-slider-slide-left-out {
  from { left: 0%; }
  to { left: -100%; }
}

@keyframes jumbo-slider-slide-left-out {
  from { left: 0%; }
  to { left: -100%; }
}

@-webkit-keyframes jumbo-slider-slide-right-out {
  from { left: 0%; }
  to { left: 100%; }
}

@keyframes jumbo-slider-slide-right-out {
  from { left: 0%; }
  to { left: 100%; }
}

.slide-left-in {
  -webkit-animation: jumbo-slider-slide-left-in 700ms ease-out;
          animation: jumbo-slider-slide-left-in 700ms ease-out;
}

.slide-right-in {
  -webkit-animation: jumbo-slider-slide-right-in 700ms ease-out;
          animation: jumbo-slider-slide-right-in 700ms ease-out;
}

.slide-left-out {
  -webkit-animation: jumbo-slider-slide-left-out 700ms ease-out;
          animation: jumbo-slider-slide-left-out 700ms ease-out;
}

.slide-right-out {
  -webkit-animation: jumbo-slider-slide-right-out 700ms ease-out;
          animation: jumbo-slider-slide-right-out 700ms ease-out;
}

.jumbo-slider {
  position: relative;
  overflow: hidden;
  clear: both;
  height: 500px;
  color: #fff;
  margin-bottom: 20px;
}

.jumbo-slider-item {
  position: absolute;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jumbo-slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.jumbo-slider-dots {
  position: absolute;
  bottom: 0.5em;
  width: 100%;
  z-index: 10;
  text-align: center;
}

.jumbo-slider-dot {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: #fff;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50%;
  margin: 0.5em;
  padding: 0;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms;
  cursor: pointer;
  outline: none;
}

.jumbo-slider-dot:hover, .jumbo-slider-dot.active {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  background-color: #ffa91b;
}

@media (max-width: 768px) {
  .jumbo-slider-item {
    height: 300px;
  }
}


.sub-header {
  height: 50px;
  line-height: 50px;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
  color: #535051;
  border-bottom: 1px solid #d0d0d0;
}

@-webkit-keyframes rotation {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotation {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 40px;
  overflow: hidden;
  z-index: 50;
  cursor: wait;
}

.loader.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: auto;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader > img {
  display: inline-block;
  -webkit-animation: rotation 1s infinite steps(8);
          animation: rotation 1s infinite steps(8);
}

.loader-error {
  text-align: center;
}

.loader-error > div {
  margin: 10px;
}


.bbs-widget {
  position: relative;
}

.bbs-widget-more-button {
  position: absolute;
  top: 10px;
  right: 0;
  border: 0;
}

.bbs-widget-list li {
  padding: 10px;
  min-height: 60px;
  overflow: hidden;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-widget-item-thumb {
  float: left;
  margin-right: 10px;
}

.bbs-widget-item-title {
  display: inline-block;
  margin: 0 10px 5px 0;
}

.bbs-widget-item-date {
  display: inline-block;
  color: #d0d0d0;
  font-size: 12px;
}

.bbs-widget-item-excerpt {
  line-height: 20px;
}


.breadcrumb {
  height: 40px;
  margin: 0 5px;
  font-size: 14px;
  text-align: right;
}

.breadcrumb-item {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.breadcrumb-item:not(:last-child):after {
  content: '\2022';
}

.breadcrumb button {
  display: inline-block;
  padding: 0 10px;
  border: 0;
  color: #595959;
  background-color: transparent;
  font-family: inherit;
}

.breadcrumb-item:not(:last-child) button:hover {
  color: #ffa91b;
  background-color: transparent;
}

.breadcrumb-item.active {
  cursor: default;
}


form {
	position: relative;
}

.form-field {
	line-height: 20px;
}

.form-label {
  width: 100%;
	font-weight: 600;
	font-size: 14px;
	color: #535051;
}

.form-label-important {
	margin-right: 5px;
	color: #ffa91b;
}

.form.horizontal .form-label {
	float: left;
	width: 25%;
	padding: 10px 25px 10px 10px;
	text-align: right;
}

.form-text {
	padding: 5px 0;
}

.form-control-row {
	margin-bottom: 10px;
}

.form-control {
	padding: 5px 0;
	font-size: 14px;
	overflow: hidden;
}

.form-control button, .form-control input, .form-control span {
	display: inline;
	vertical-align: middle;
	line-height: 30px;
}

.form-control a {
	text-decoration: underline;
}

.form-buttons {
  margin-top: 5px;
}

.form-buttons button[type="submit"] {
  margin-right: 10px;
}

@media (max-width: 768px) {
	.form.horizontal .form-label {
		float: none;
		width: 100%;
		padding: 0;
		text-align: left;
	}

  .form-buttons {
    text-align: center;
  }

  .form-buttons button {
    margin: 5px;
  }
}


.login-panel {
  width: 100%;
  height: 400px;
  margin-bottom: 200px;
  background: -webkit-linear-gradient(top, #d0d0d0, #fff);
  background: linear-gradient(to bottom, #d0d0d0, #fff);
}

.login-form {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding-top: 85px;
}

.login-header {
  height: 50px;
  margin-bottom: 20px;
  font-size: 36px;
  border-bottom: 1px solid #535051;
  text-align: center;
}

.login-form input:not([type=checkbox]) {
  width: 200px;
  margin-bottom: 10px;
}

.login-form input[type=checkbox] {
  height: 14px;
  vertical-align: middle;
}

.login-form label {
  display: block;
  margin-bottom: 20px;
}

.login-form button[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  width: 90px;
  height: 70px;
}

.login-buttons {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.login-buttons a {
  display: inline-block;
  margin: 0 3px;
}

.login-buttons button {
  font-size: 12px;
}

.login-social-services {
  margin: 2em;
  text-align: center;
}


.page-header > .container {
  min-height: 100px;
  padding: 32px 0;
  background: -webkit-linear-gradient(left, #d0d0d0, #fff);
  background: linear-gradient(to right, #d0d0d0, #fff);
  font-size: 36px;
  text-align: center;
}

@media (max-width: 768px) {
  .page-header > .container {
    min-height: 60px;
    padding: 18px 0;
    font-size: 24px;
  }
}


.stepper {
  margin: 20px 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.step {
  position: relative;
  display: inline-block;
  padding: 13px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #d0d0d0;
}

.step:not(:last-child)::after {
  position: absolute;
  content: '';
  top: -5px;
  right: -15px;
  width: 38px;
  height: 38px;
  border-right: 12px solid #fff;
  border-bottom: 12px solid #fff;
  -webkit-transform: scaleX(0.6) rotate(-45deg);
          transform: scaleX(0.6) rotate(-45deg);
  z-index: 1;
}

.step.active {
  background-color: #ffa91b;
}

@media (max-width: 768px) {
}


.register-content {
  -webkit-animation: fade-in 300ms linear;
          animation: fade-in 300ms linear;
}

.register-help {
  margin: 20px 0;
  line-height: 20px;
  text-align: center;
}

.register-help p {
  margin-bottom: 10px;
}

.register-buttons {
  margin: 20px 0;
  text-align: center;
}

.register-buttons button {
  margin: 0 5px;
}

.register-terms textarea {
  line-height: 18px;
}

.register-terms label {
  display: block;
  margin-top: 10px;
  font-weight: normal;
}

.register-terms span, .register-terms input {
  vertical-align: middle;
}

.tab-menu {
  margin: 20px 0;
  text-align: center;
}

.tab-item {
  display: inline-block;
  cursor: pointer;
}

.tab-item button {
  display: inline-block;
  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
  border: 0;
  font-size: 18px;
  font-family: inherit;
  color: #595959;
  background-color: transparent;
}

.tab-item.active, .tab-item:hover {
  border-bottom: 2px solid #535051;
}

@media (max-width: 768px) {
  .tab-item button {
    height: 30px;
    padding: 5px 10px;
    font-size: 16px;
  }
}


.about-header {
  height: 240px;
}

#about-header-image-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

#about-header-image-2 {
  position: absolute;
  left: 200px;
  top: 90px;
}

#about-header-image-3 {
  position: absolute;
  right: 24px;
  top: 24px;
}

#about-header-image-4 {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

@-webkit-keyframes rotation {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotation {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.activity-list {
  margin: 20px 0;
}

.activity-item {
  color: #535051;
  margin: 0 15px;
}

.activity-date {
  text-align: right;
  font-size: 18px;
  margin: 10px 20px;
}

.activity-content {
  border-left: 2px solid #d0d0d0;
  line-height: 25px;
}

.activity-picture {
  margin: 20px;
}

.activity-message {
  white-space: pre-wrap;
  margin: 10px 20px;
}

.activity-tag {
  color: #ffa91b;
}

.activity-tag:hover {
  text-decoration: underline;
}

.activity-more {
  margin: 40px;
  text-align: center;
}

.activity-more > button > img {
  -webkit-animation: rotation 1s infinite steps(8);
          animation: rotation 1s infinite steps(8);
}

@media (max-width: 768px) {
  .activity-list {
    margin: 0;
  }

  .activity-date {
    margin: 40px 0 20px 0;
    text-align: center;
  }
}


.pagination {
  text-align: center;
}

.pagination button {
  display: inline-block;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  border: 0;
  font-size: 1em;
  font-family: inherit;
  color: #595959;
  background-color: transparent;
}

.pagination button:hover {
  border-bottom: 1px solid #ffa91b;
}

.pagination .active button {
  display: inline-block;
  color: #ffa91b;
  border-bottom: 1px solid #ffa91b;
}


.course-image {
  flex: none;
  width: 230px;
  height: 200px;
  margin-bottom: 40px;
  text-align: center;
}

.course-content {
  margin-bottom: 40px;
}

.course-title {
  font-size: 24px;
  margin-bottom: 5px;
}

.course-description {
  line-height: 20px;
}

.course-search-form {
  margin: 20px 0 40px 0;
  text-align: center;
}

.course-search-form img {
  margin: 0 10px;
}

.course-search-form img, .course-search-form span {
  vertical-align: middle;
}

.course-list-empty {
  margin: 40px;
  text-align: center;
}

.course-buttons button {
  margin-right: 10px;
}


.course-view-buttons {
  margin: 40px;
  text-align: center;
}

.course-view-buttons button {
  margin: 0 5px;
}

.course-application-item {
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid #d0d0d0;
  border-bottom: 0;
  overflow: hidden;
}

.course-application-item:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.course-application-name {
  width: 80px;
  line-height: 20px;
  padding: 10px;
}

.course-application-message {
  flex: 1 1;
  line-height: 20px;
  padding: 10px;
}

.course-application-date {
  line-height: 20px;
  padding: 10px;
  text-align: center;
}

.course-application-item button {
  border: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 12px;
}


.shop-item-not-ready {
  margin: 40px 0;
  text-align: center;
}

.shop-item-search-form {
  padding: 5px;
  text-align: center;
}

.shop-item-list {
  margin: 20px 0;
}

.shop-item {
  margin-bottom: 20px;
  text-align: center;
}

.shop-item-image {
  margin-bottom: 10px;
}

.shop-item-title {
  height: 30px;
  line-height: 20px;
  padding: 5px;
  font-size: 18px;
}

.shop-item-description {
  height: 30px;
  line-height: 20px;
  padding: 5px;
}

.shop-item-price {
  height: 30px;
  line-height: 20px;
  padding: 5px;
  font-size: 18px;
}


.bbs-list-table-title {
  vertical-align: middle;
}

.bbs-list-table-category-badge {
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
}

.bbs-list-table-comment-count {
  margin: 0 5px;
  color: #ffa91b;
}

.bbs-list-table-comment-count img {
  vertical-align: middle;
}

.bbs-list-table-comment-count span {
  font-size: 12px;
}

.bbs-list-table-item img {
  vertical-align: middle;
  margin: 0 5px;
}

.bbs-list-table-item {
  margin: 20px 0;
}

.bbs-list-table {
  width: 100%;
  margin: 0;
  padding: 0;
}

.bbs-list-table tr {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-list-table tbody a:hover {
  text-decoration: underline;
}

.bbs-list-table th {
  min-height: 40px;
  line-height: 20px;
  margin: 0;
  padding: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #535051;
  border: 0;
}

.bbs-list-table td {
  min-height: 40px;
  line-height: 20px;
  margin: 0;
  padding: 10px;
  border: 0;
  color: #535051;
}

.bbs-list-table td.fit-content {
  width: 15%;
  white-space: nowrap;
}


.bbs-list-card-item {
  position: relative;
  padding: 15px;
  overflow: hidden;
}

.bbs-list-card-category-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
}

.bbs-list-card-thumbnail {
  text-align: center;
}

.bbs-list-card-thumbnail img {
  width: 100%;
  height: auto;
}

.bbs-list-card-title {
  margin: 10px 0;
  line-height: 16px;
  font-size: 14px;
  color: #535051;
}

.bbs-list-card-secret {
  margin: 0 5px;
}

.bbs-list-card-secret img {
  vertical-align: middle;
}

.bbs-list-card-description {
  margin-top: 15px;
  color: #d0d0d0;
}

.bbs-list-card-date {
  display: block;
  float: right;
}

.bbs-list-card-date span {
  font-size: 12px;
}

.bbs-list-card-author {
  font-size: 12px;
}

.bbs-list-card-comment-count {
  display: block;
  float: right;
  margin: 0 10px;
}

.bbs-list-card-comment-count span {
  font-size: 12px;
}

.bbs-list-card-comment-count img {
  margin: 0 5px;
  vertical-align: middle;
}

.bbs-list-media-item {
  border-bottom: 1px solid #d0d0d0;
  overflow: hidden;
}

.bbs-list-media-thumbnail {
  float: left;
}

.bbs-list-media-thumbnail img {
  width: 80px;
  height: 80px;
  margin: 10px 15px 10px 10px;
}

.bbs-list-media-title {
  margin: 10px 0 5px 0;
  font-size: 14px;
  font-weight: 600;
}

.bbs-list-media-excerpt {
  line-height: 16px;
  font-size: 14px;
  margin: 5px 10px 10px 0;
}

.bbs-list-media-description {
  margin: 10px 0;
  font-size: 12px;
  color: #d0d0d0;
}

.bbs-list-media-description span {
  margin-right: 10px;
  vertical-align: middle;
} 

.bbs-list-media-description img {
  margin: 0 5px;
  vertical-align: middle;
}


.bbs-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 5px 0;
}

.bbs-search-form select {
  margin-right: 10px;
  padding: 0 5px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  color: #535051;
  background-color: #fff;
  vertical-align: middle;
}

.bbs-search-form a, .bbs-search-form button {
  vertical-align: middle;
}

.bbs-post-list-empty {
  margin: 40px 0 60px 0;
  text-align: center;
}


.bbs-post-view {
  position: relative;
  overflow: hidden;
}

.bbs-post-view-header {
  margin: 20px 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-post-view-title {
  padding: 13px 13px 13px 0;
}

.bbs-post-view-title span:first-child {
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}

.bbs-category-badge {
  margin: 0 8px;
  padding: 2px 8px;
  border-radius: 2px;
  color: #fff;
  background-color: #a8cf38;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
}

.bbs-post-view-description {
  float: right;
  margin: 0;
  padding: 9px;
}

.bbs-post-view-description li {
  display: inline-block;
  margin: 8px;
  padding: 0;
}

.bbs-post-view-description img {
  vertical-align: middle;
  margin-right: 5px;
}

.bbs-post-view-description span {
  font-size: 14px;
}

.bbs-post-view-footer {
  margin: 20px 0;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-post-view-buttons {
  margin: 20px 0;
  text-align: right;
}

.bbs-post-view-buttons button {
  margin-left: 10px;
}

.bbs-post-view-file-list {
  overflow: hidden;
}

.bbs-post-view-file-list ul {
  float: right;
  padding: 0 10px;
}

.bbs-post-view-file-list li {
  line-height: 20px;
  text-align: right;
}

.bbs-post-view-file-list li span {
  margin: 0 5px;
}

.bbs-post-view-file-list a:hover {
  text-decoration: underline;
}

.bbs-comment-list form {
  margin-bottom: 20px;
}

.bbs-comment-list textarea {
  width: 100%;
}

.bbs-comment-item {
  position: relative;
  display: flex;
  border-bottom: 1px solid #d0d0d0;
  -webkit-animation: fade-in 300ms linear;
          animation: fade-in 300ms linear;
}

.bbs-comment-avatar {
  width: 48px;
  height: 48px;
  margin: 6px;
  border: 1px solid #d0d0d0;
  background-position: center center;
  background-size: cover;
}

.bbs-comment-body {
  flex: 1 1;
}

.bbs-comment-description {
  margin: 6px;
  font-size: 12px;
  color: #d0d0d0;
}

.bbs-comment-description img {
  display: inline-block;
}

.bbs-comment-description span {
  display: inline-block;
  margin-right: 10px;
}

.bbs-comment-content {
  margin: 6px 20px 6px 6px;
  line-height: 16px;
  white-space: pre-wrap;
  font-size: 14px;
  color: #535051;
}

.bbs-comment-delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  border: 0;
  padding: 2px;
  margin: 0;
}

.bbs-comment-delete:hover {
  background-color: #535051;
}


.file-uploader-list {
  margin-bottom: 10px;
  border-bottom: 0;
}

.file-uploader-list li {
  display: flex;
  align-items: center;
  border-top: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}

.file-uploader-list li:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.file-uploader-thumbnail {
  width: 40px;
  height: 40px;
}

.file-uploader-description {
  flex: 1 1;
  padding: 0 10px;
}

.file-uploader-delete {
  margin: 0 5px;
}


.bbs-write-form {
  
}

