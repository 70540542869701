
.register-content {
  animation: fade-in 300ms linear;
}

.register-help {
  margin: 20px 0;
  line-height: 20px;
  text-align: center;
}

.register-help p {
  margin-bottom: 10px;
}

.register-buttons {
  margin: 20px 0;
  text-align: center;
}

.register-buttons button {
  margin: 0 5px;
}

.register-terms textarea {
  line-height: 18px;
}

.register-terms label {
  display: block;
  margin-top: 10px;
  font-weight: normal;
}

.register-terms span, .register-terms input {
  vertical-align: middle;
}