
.bbs-widget {
  position: relative;
}

.bbs-widget-more-button {
  position: absolute;
  top: 10px;
  right: 0;
  border: 0;
}

.bbs-widget-list li {
  padding: 10px;
  min-height: 60px;
  overflow: hidden;
  border-bottom: 1px solid #d0d0d0;
}

.bbs-widget-item-thumb {
  float: left;
  margin-right: 10px;
}

.bbs-widget-item-title {
  display: inline-block;
  margin: 0 10px 5px 0;
}

.bbs-widget-item-date {
  display: inline-block;
  color: #d0d0d0;
  font-size: 12px;
}

.bbs-widget-item-excerpt {
  line-height: 20px;
}
